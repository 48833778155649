import { Controller } from "@hotwired/stimulus"
import {
  httpRequest,
  show
} from "./../helpers";

export default class extends Controller {
  static targets = [ "label", "subscriptionButton" ]
  static values = {
    subscribed: String,
    jobApplicationSlug: String
  }

  connect() {
    this.buttonSpacer = document.getElementById("buttonSpacer")
    this.spinner = document.getElementById("spinner")
    this.notificationSpacer = document.getElementById("notificationSpacer")
    this.notification = document.getElementById("notification")
  }

  subscribe() {
    var subscriptionButton = this.buttonSpacer.childNodes[0]
    this.buttonSpacer.replaceChild(this.spinner, subscriptionButton);
    show(this.buttonSpacer.childNodes[0])
    httpRequest(`/job_applications/${this.jobApplicationSlugValue}/subscribe_to_newsletter`, "GET")
      .then(response => {
        if (!response.ok) throw new Error("Network response was not OK")
        return response
      })
      .then(response => {
        this.onSubscribeToNewsletterSuccess()
      })
      .catch(error => {
        this.onFailedToSubscribeToNewsletter()
      })
  }

  onSubscribeToNewsletterSuccess() {
    this.buttonSpacer.removeChild(this.buttonSpacer.firstElementChild)
    this.labelTarget.innerHTML = this.subscribedValue
  }

  onSubscribeToNewsletterFailed() {
    this.notificationSpacer.innerHTML = this.notification.innerHTML
    this.buttonSpacer.replaceChild(subscriptionButton, this.buttonSpacer.childNodes[0]);
  }
}
