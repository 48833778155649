import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
  httpRequest,
  show,
  hide
} from "./../helpers";

export default class extends Controller {
  static targets = ["input", "notification"];

  connect() {
    this.dropZone = createDropZone(this);
    this.dropZone.hiddenFileInput.setAttribute("id", "uploadDocument")
    this.inputTarget.disabled = true;
    this.bindEvents();
    Dropzone.autoDiscover = false;
    this.spinner = document.getElementById("spinner")
    this.spinnerSpacer = document.getElementById("spacer")
    this.notificationSpacer = document.getElementById("notificationSpacer")
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
      if (file.controller.signed_id) {
        httpRequest(`/storage/delete_document/${file.controller.signed_id}`, "DELETE")
      }
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("success",  file => {
      if (file.controller.signed_id) {
        // this.onCVParsingProcessing()
        // this.uploadDocumentToMega(file)
      }
    })
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.dataset.directUploadUrl
  }

  get acceptedFiles() {
    return this.inputTarget.accept
  }

  uploadDocumentToMega(file) {
    httpRequest(`/candidates/upload_document/${file.controller.signed_id}`, "GET")
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not OK")
        }
        return response.json();
      })
      .then(response => {
        if (response.document_id) {
          setTimeout(() => this.autocompleteForm(response.document_id), 2000)
        } else {
          this.onCVParsingFailed()
        }
      })
      .catch(error => {
        this.onCVParsingFailed()
      })
  }

  autocompleteForm(document_id) {
    httpRequest(`/candidates/autocomplete_form/${document_id}`, "GET")
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not OK")
        }
        return response.json();
      })
      .then(candidate => {
        if (candidate.status == "success") {
          this.onCVParsingSuccess(candidate)
        } else if (candidate.status == "processing") {
          setTimeout(() => this.autocompleteForm(document_id), 2000)
        } else {
          this.onCVParsingFailed()
        }
      })
      .catch(error => {
        this.onCVParsingFailed()
      })
  }

  onCVParsingSuccess(candidate) {
    this.setInputField("last_name", candidate.last_name)
    this.setInputField("first_name", candidate.first_name)
    this.setInputField("email", candidate.email)
    this.setInputField("mobile_phone", candidate.mobile_phone)
    this.onCVParsingStopped()
  }

  onCVParsingStopped() {
    hide(this.spinner)
    show(this.spinnerSpacer)
  }

  onCVParsingProcessing() {
    hide(this.spinnerSpacer)
    show(this.spinner)
  }

  onCVParsingFailed() {
    this.onCVParsingStopped()
    this.notificationSpacer.innerHTML = this.notificationTarget.innerHTML
  }

  setInputField(attribute, value) {
    if (value.length != 0 && document.querySelector(`#candidate_person_attributes_${attribute}`).value.length == 0) {
      document.querySelector(`#candidate_person_attributes_${attribute}`).value = value
    }
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, blob) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.signed_id = blob.signed_id
        this.hiddenInput.value = this.signed_id
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: 4,
    maxFilesize: 10,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: true,
    autoQueue: false,
    dictRemoveFile: "x",
  });
}
